import React from "react";
import Bullet2 from "../../../../assets/icons/Bullet2.svg";

const SectionTwo = () => {
  const sectorsArray = [
    {
      name: "Large New Build",
    },
    {
      name: "SMR/ MMR",
    },
    {
      name: "Fusion",
    },
    {
      name: "Decommissioning",
    },
    {
      name: "Radioactive Waste Management",
    },
    {
      name: "Fuel Handling ",
    },
    {
      name: "Uranium Mining ",
    },
  ];
  const repeatedSectorsArray = Array(10).fill(sectorsArray).flat();
  const print = () => {
    console.log(repeatedSectorsArray.length);
  };
  print();

  return (
    <>
      <div className="bg-white flex xl:flex-row flex-col justify-center items-center xl:px-[64px] xl:gap-[125px] gap-[40px] xl:py-[115px] pt-[65px] ">
        <div>
          <h1 className="xl:text-[47px] text-[36px] font-[700] xl:min-w-[304px] xl:leading-[70.5px] text-[#000000] text-center">
            <span className="text-[#8A3DEF]">Sectors</span> We Support
          </h1>
        </div>
        <div className=" flex xl:flex-wrap xl:flex-row flex-col items-start xl:gap-[45px] gap-[20px] px-[40px] xl:px-0">
          {sectorsArray.map((data, index) => {
            return (
              <div
                key={index}
                className="flex gap-[15px] items-center justify-center"
              >
                <div className="w-[12px] h-[12px] bg-[#8A3DEF] rounded-full"></div>
                <h1 className="font-poppins font-[700]  text-[19px] text-[#000000] leading-[28.5px] ">
                  {data.name}
                </h1>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
